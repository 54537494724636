<template>
    <el-dialog :append-to-body="true" title="资源详情" id="detailsPage" :fullscreen="true" :close-on-click-modal="false"
               :visible.sync="visible" :before-close="close">
        <div class="modelBox" v-loading="loading">
            <el-carousel class="h100" :autoplay="false" indicator-position="none" type="card"
                         @change="changeImg" v-if="fileType == '1'">
                <el-carousel-item v-for="(item, index) in dataList" :key="item.id">
                    <el-image :src="item.url" fit="contain">
                        <el-image slot="placeholder" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                        <el-image slot="error" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                    </el-image>
                </el-carousel-item>
            </el-carousel>
            <el-row :gutter="20" class="h100" v-else>
                <el-col :span="fileType != '0' || fileType != '1' ? 24 : 18" class="infoBox h100">
                    <iframe v-if="fileType == '0'" id="mainIframe" ref="mainIframe" name="mainIframe"
                            :src="iframeSrc" frameborder="0" width="100%" class="iframe" height="100%"/>
                    <iframe allowfullscreen="true" v-if="fileType != '0' && fileType != '1'" id="frame"
                            :src="this.$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl"
                            style="width: 100%;height: 100%;"></iframe>
                </el-col>
                <el-col :span="6" class="h100" v-if="fileType != '0' && fileType != '1'">
                    <el-table
                            :data="dataList"
                            size="small"
                            height="calc(100% - 60px)"
                            class="table pointer" @row-click="clickRow">
                        <el-table-column prop="fileName" show-overflow-tooltip label="文件名称"></el-table-column>
                    </el-table>
                    <el-pagination class="text_center m_t1"
                                   @size-change="sizeChangeHandle"
                                   @current-change="currentChangeHandle"
                                   :pager-count="3"
                                   :current-page="pageNo"
                                   :page-size="pageSize"
                                   :total="total"
                                   background
                                   small
                                   layout="total, prev, pager, next">
                    </el-pagination>
                </el-col>
            </el-row>
        </div>
        <div class="flex_b_c">
            <div>
                <el-tag
                        v-for="(item, index) in dynamicTags" :key="item.id" v-if="index < 3"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(item, index)">
                    {{item.labelName}}
                </el-tag>
                <el-popover v-if="dynamicTags.length > 3"
                            placement="right"
                            width="400"
                            trigger="click">
                    <el-tag
                            v-for="(item, index) in dynamicTags" :key="item.id" v-if="index > 2"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(item, index)">
                        {{item.labelName}}
                    </el-tag>
                    <el-button slot="reference" size="small" class="moreBtn">...</el-button>
                </el-popover>
                <el-select class="input-new-tag" size="small"
                           v-model="inputValue" v-if="inputVisible"
                           filterable
                           allow-create
                           default-first-option @change="changeIpt">
                    <el-option
                            v-for="item in tagOpt"
                            :key="item.id"
                            :label="item.labelName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-button type="primary" plain v-else class="button-new-tag" size="small"
                           @click="showInput">
                    +添加标签
                </el-button>
            </div>
            <div class="flex_r_c">
                <el-select v-if="fileType == '0'" size="small" class="m_r1" v-model="modelType" @change="changeSelect"
                           placeholder="请选择" style="width: 150px">
                    <el-option
                            v-for="item in modelTypeOpt"
                            :key="item.id"
                            :label="item.fieldName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-tooltip class="item" effect="dark" content="测量" placement="top">
                    <el-button size="small" :type="threeIframeType ? '' : 'primary'"
                               v-if="hasPermission('admin:rangingThree') && fileType == 0"
                               @click="rangingThree" circle>
                        <i class="icon-celiangleixing iconfont"/>
                    </el-button>
                </el-tooltip>
                <el-button size="small" type="primary" plain icon="el-icon-download" circle
                           @click="downloadThree"></el-button>
                <el-popover class="info m_l1"
                            placement="top"
                            width="400"
                            trigger="hover">
                    <!-- 没有数据占位-->
                    <div v-if="!info">暂无数据</div>
                    <div v-if="info!=null">
                        <div v-if="info.fileName">文件名称：{{info.fileName}}</div>
                        <div v-if="info.fileSizeFormat">文件大小：{{info.fileSizeFormat}}</div>
                        <div v-if="info.fileDesc">文件描述：{{info.fileDesc}}</div>
                        <div v-if="info.shotUser">拍摄人：{{info.shotUser}}</div>
                        <div v-if="info.shotTime">拍摄日期：{{info.shotTime}}</div>
                        <div v-if="info.shotTask">拍摄任务：{{info.shotTask}}</div>
                        <div v-if="info.shotAddress">拍摄地点：{{info.shotAddress}}</div>
                        <div v-if="info.shotDevice">拍摄设备：{{info.shotDevice}}</div>
                        <div v-if="info.resourceWidth">图片宽度：{{info.resourceWidth}}</div>
                        <div v-if="info.resourceHeight">图片高度：{{info.resourceHeight}}</div>
                        <div v-if="info.focalLength">焦距：{{info.focalLength}}</div>
                        <div v-if="info.shutter">快门：{{info.shutter}}</div>
                        <div v-if="info.iso">ISO：{{info.iso}}</div>
                        <div v-if="info.aperture">光圈：{{info.aperture}}</div>
                    </div>
                    <el-button slot="reference" size="small" type="text" icon="el-icon-info"></el-button>
                </el-popover>
            </div>
        </div>
        <div class="m_t1 m_b1 f_w">关联文件</div>
        <el-tabs v-model="tabType" type="card" @tab-click="handleClick">
            <el-tab-pane label="三维资源" name="0"></el-tab-pane>
            <el-tab-pane label="图片资源" name="1"></el-tab-pane>
            <el-tab-pane label="视频资源" name="3"></el-tab-pane>
            <el-tab-pane label="音频资源" name="2"></el-tab-pane>
            <el-tab-pane label="出版物数据" name="4"></el-tab-pane>
            <el-tab-pane label="文献数据" name="5"></el-tab-pane>
            <el-tab-pane label="文件资料" name="6"></el-tab-pane>
            <el-tab-pane label="其他资源" name="7"></el-tab-pane>
        </el-tabs>
        <el-table
                :data="dataListType"
                size="small"
                height="calc(100vh - 400px)"
                class="table"
                @row-click="jump"
        >
            <el-table-column
                    prop="resource_name"
                    show-overflow-tooltip
                    label="资源名称">
                <template slot-scope="scope">
                    <!-- <div @click="jump(scope.row)">{{scope.row.resource_name}}</div>-->
                    <div>{{scope.row.resource_name}}</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="resource_desc"
                    show-overflow-tooltip
                    label="资源描述">
            </el-table-column>
            <el-table-column
                    prop="group_name"
                    show-overflow-tooltip
                    label="分组">
            </el-table-column>
            <el-table-column
                    prop="resource_key"
                    show-overflow-tooltip
                    label="关键字">
            </el-table-column>
            <el-table-column
                    prop="collection_name"
                    show-overflow-tooltip
                    label="关联藏品名 ">
            </el-table-column>
        </el-table>
        <el-pagination class="text_center m_t2"
                       @size-change="sizeChangeHandleType"
                       @current-change="currentChangeHandleType"
                       :current-page="pageNoType"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="pageSizeType"
                       :total="totalType"
                       background
                       layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>
        <ViewForm ref="viewForm"></ViewForm>
    </el-dialog>

</template>

<script>
    import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
    import ViewThreeForm from '../gather/resource/three/viewThreeForm'
    import {Base64} from 'js-base64'

    export default {
        components: {ViewForm, ViewThreeForm},
        data() {
            return {
                loading: false,
                threeModuleId: "948974400588218368",
                visible: false,
                fileType: '',
                fileTypeSuffix: '',//文件后缀
                dataList: [],
                resourcesPreviewFileType: [],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                info: {},
                fileUrl: '',
                // 标签
                dynamicTags: [],
                inputVisible: false,
                inputValue: '',
                tagOpt: [],
                mtlUrl: '',
                objUrl: '',
                objFile: '',
                mtlFile: '',
                fileList: [],
                iframeSrc: '/static/threeJs/examples/basics_obj.html',
                threeIframeType: true,
                modelType: '',
                tabType: 0,
                modelTypeOpt: [],
                dataListType: [],
                pageNoType: 1,
                pageSizeType: 10,
                totalType: 0,
                resourceId: '',
                resourceInfo: [],
            }
        },

        methods: {
            init(id, resourceType) {
                this.threeIframeType = true
                this.resourceId = id
                this.visible = true
                this.fileType = resourceType
                this.resourcesPreviewFileType = this.getResourcePreviewFileFormat(this.fileType)
                this.info = {}
                this.$nextTick(() => {
                    this.refreshList()
                    this.labelList()
                    this.queryLabelNoRe()
                    if (this.fileType == 0) {
                        this.getResourceInfo()
                    } else {
                        this.refreshInfo()
                    }
                })
            },
            close() {
                this.visible = false
                this.inputVisible = false
            },
            // 获取业务字段
            getFieldByResourceName() {
                var that = this;
                let parma = {
                    resourcesId: this.resourceId
                }
                that.$axios(this.api.resources.resourceModel, parma, "get").then((res) => {
                    if (res.status) {
                        that.modelTypeOpt = res.data
                        that.modelType = that.modelTypeOpt[that.modelTypeOpt.length - 1].id
                        this.refreshInfo()
                        that.changeSelect(that.modelType)
                    }
                })
            },
            // 资源详情
            getResourceInfo() {
                let param = {
                    resourceId: this.resourceId
                }
                this.$axios(this.api.resources.showThreeResource, param, 'get').then(data => {
                    if (data && data.status) {
                        this.resourceInfo = data.data.resources
                        this.fileList = data.data.stringMapMap
                        this.getFieldByResourceName();
                    }
                })
            },
            changeSelect(id, iframeSrc) {
                var that = this
                let newarr = this.fileList[id];
                if (id == '955785177131384832') {
                    this.$message('预览高模文件，请保持客户端内存充足')
                }
                if (!newarr) {
                    this.$message('无三维文件，请上传三维文件或设置主显')
                    this.resourceExists = true;
                    return;
                }
                let iframeData
                if (newarr.obj && newarr.mtl && newarr.img) {  // stl模型
                    this.iframeSrc = iframeSrc ? iframeSrc : '/static/threeJs/examples/basics_obj.html'
                    this.inputForm = newarr.obj
                    that.objFile = newarr.obj
                    that.mtlFile = newarr.mtl
                    that.imgFile = newarr.img
                    iframeData = {
                        mtlUrl: newarr.mtl.url,
                        objUrl: newarr.obj.url,
                        imgUrl: newarr.img,
                    }
                } else if (newarr.stl) {  // stl模型
                    this.iframeSrc = '/static/threeJs/examples/basics_stl.html'
                    this.inputForm = newarr.stl
                    iframeData = {
                        stlUrl: newarr.stl.url,
                    }
                } else {
                    this.$message('缺失文件')
                    this.resourceExists = true;
                    return;
                }
                // 使用ref 获取 dom
                const mapFrame = this.$refs['mainIframe']
                // 因为iframe页面打开就已经加载 获取接口成功后刷新他
                mapFrame.contentWindow.location.reload()
                if (mapFrame.attachEvent) {
                    // 兼容浏览器判断 // iframe的加载完成函数
                    mapFrame.attachEvent('onload', function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    })
                } else {
                    // iframe的加载完成函数
                    mapFrame.onload = function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    }
                }
                return
            },
            // 下载
            downloadThree() {
                this.$message.info("资源文件在进行下载中，请勿重复点击")
                const a = document.createElement('a')
                if (0 == this.fileType) {
                    // stl 下载方式
                    if (this.inputForm.fileFormat === 'stl') {
                        const stlUrl = this.inputForm.url
                        const stlName = this.inputForm.fileName
                        fetch(stlUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                            a.href = URL.createObjectURL(blob)
                            a.download = stlName, // 下载文件的名字
                                document.body.appendChild(a)
                            a.click()
                            this.saveLog(stlName)
                        })
                        return
                    }
                    if ((this.objFile == null || this.objFile.url == null) && (this.mtlFile == null || this.mtlFile.url == null)) {
                        this.$message.error("当前资源未存在文件或文件损坏！")
                        return
                    }
                    this.saveLog(this.mtlFile.fileName)
                    this.saveLog(this.objFile.fileName)
                    let urls = [this.mtlFile.url, this.objFile.url]
                    this.imgFile.map(item => {
                        this.saveLog(item.fileName)
                        urls.push(item.url)
                    })
                    this.$nextTick(() => {
                        this.$axios(this.api.digital.downThreeZip, urls, "post", 'blob').then((res) => {
                            if (res.status) {
                                let blob = new Blob([res.data], {type: 'application/zip'})
                                let link = document.createElement('a')
                                link.download = decodeURI('三维资源')
                                link.href = window.URL.createObjectURL(blob)
                                link.click()
                            }
                        })
                    })
                } else {
                    if (this.info == null || this.info.url == '') {
                        this.$message.error("当前资源未存在文件或文件损坏！")
                        return
                    }
                    if (this.fileType == '1' || this.fileTypeSuffix == 'pdf') {
                        //水印下载
                        this.$axios(this.api.rcPage.downloadResources, {
                            'fileId': this.info.id,
                        }, 'get', 'blob').then(res => {
                            let blob
                            if (this.fileType == '1') {
                                blob = new Blob([res.data], {type: 'image/jpeg'})
                            } else {
                                blob = new Blob([res.data], {type: 'application/pdf'})
                            }
                            let link = document.createElement('a')
                            link.download = decodeURI(this.info.fileName)
                            link.href = window.URL.createObjectURL(blob)
                            link.click()
                            this.saveLog(this.info.fileName)
                        })
                    } else {
                        const url = this.info.url
                        fetch(url).then(res => res.blob()).then(blob => {
                            a.href = URL.createObjectURL(blob)
                            a.download = this.info.fileName;
                            document.body.appendChild(a)
                            a.click();
                            this.saveLog(this.info.fileName)
                        })
                    }
                }


            },
            // 测距
            rangingThree() {
                this.threeIframeType = !this.threeIframeType
                if (!this.threeIframeType) {
                    this.changeSelect(this.modelType, '/static/threeJs/examples/test_obj.html')
                } else {
                    this.changeSelect(this.modelType)
                }
            },

            //标签展示
            labelList() {
                this.$axios(this.api.resources.queryLabelRe, {
                    'resourceId': this.resourceId,
                }, 'get').then(res => {
                    if (res.status) {
                        this.dynamicTags = res.data
                    }
                })
            },
            //标签匹配
            queryLabelNoRe() {
                this.$axios(this.api.resources.queryLabelNoRe, {
                    'resourceId': this.resourceId,
                }, 'get').then(res => {
                    if (res.status) {
                        this.tagOpt = res.data
                    }
                })
            },
            jump(row) {
                if (row.resource_type == 0) { //三维
                    this.$refs.viewThreeForm.init(row);
                } else {
                    this.$refs.viewForm.init(row.id, row.resource_type, this.threeModuleId);
                }
            },

            // 获取文件后缀识别文件类型
            getFileType(data) {
                let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                let namelength = data.length;//取到文件名长度
                this.fileTypeSuffix = data.substring(first + 1, namelength);//截取获得后缀名
                if (this.resourcesPreviewFileType.indexOf(this.fileTypeSuffix) == -1) {
                    this.$message.warning("该文件不支持预览，请下载后本地查看")
                }
            },

            // 数据详情查询
            refreshInfo() {
                if (this.fileType != 0) {
                    this.modelType = null
                }
                let size = this.pageSize
                if (this.fileType == 1) {
                    size = 100000
                }
                this.$axios(this.api.resources.getImageByResourceId, {
                    'current': this.pageNo,
                    'size': size,
                    'resourceId': this.resourceId,
                    'modelType': this.modelType
                }, 'get').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total)
                        this.info = this.dataList[0];
                        this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
                        if (this.info != null && this.fileType != 0) {
                            this.getFileType(this.info.url);
                            this.getResourceFilesWatermark()
                        }
                    }
                })

            },
            // 数据列表查询
            refreshList() {
                this.$axios(this.api.resources.getResourcesList, {
                    id: this.resourceId,
                    type: this.tabType,
                    current: this.pageNoType,
                    size: this.pageSizeType,
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataListType = data.data.records;
                        this.totalType = parseInt(data.data.total);
                    }
                })
            },
            // 水印
            getResourceFilesWatermark() {
                let first = this.info.url.lastIndexOf(".");
                let nameLength = this.info.url.length;
                let fileFormat = this.info.url.substring(first + 1, nameLength).toLowerCase();
                let fileType = ['gif', 'jpg', 'jpeg', 'png', 'pdf']
                if (fileType.indexOf(fileFormat) == -1) {
                    return
                }
                this.loading = true
                this.$axios(this.api.digital.getResourceFilesWatermark, {
                    id: this.info.id,
                }, 'get').then(res => {
                    if (res.status && res.data) {
                        if (res.data.url.indexOf('http') > -1) {
                            this.$set(this.info, 'url', res.data.url)
                            this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
                        }
                    }
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                })
            },
            // 图片切换
            changeImg(val) {
                this.info = this.dataList[val];
                this.getFileType(this.info.url);
                this.getResourceFilesWatermark()
            },
            // 点击表格行
            clickRow(val) {
                this.info = val;
                this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
                this.getFileType(this.info.url);
                this.getResourceFilesWatermark()
            },
            // 关闭标签
            handleClose(item, index) {
                this.$axios(`${this.api.rcPage.queryLabelRemove}/${item.id}`, {}, 'Delete').then(res => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.labelList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 显示添加标签输入框
            showInput() {
                this.inputVisible = true;
            },
            changeIpt(val) {
                if (val.trim().length == 0) {
                    this.$message('请输入标签内容')
                    return false
                }
                let item = this.tagOpt.filter(v => v.id == val)
                let obj = {}
                if (item.length != 0) {
                    obj = {
                        lableName: item[0].labelName,
                        resourceId: this.resourceId,
                    }
                } else {
                    obj = {
                        lableName: val,
                        resourceId: this.resourceId,
                    }
                }
                this.$axios(this.api.resources.saveLabel, obj, 'post').then(res => {
                    if (res.status) {
                        this.labelList();
                        this.$message.success('新增标签成功')
                    } else {
                        this.$message.error(res.msg)
                    }
                    this.queryLabelNoRe();
                })
                this.inputValue = '';
                this.inputVisible = false;
            },
            handleClick(val) {
                this.refreshList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshInfo()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshInfo()
            },
            // 每页数
            sizeChangeHandleType(val) {
                this.pageSizeType = val
                this.pageNoType = 1
                this.refreshList()

            },
            // 当前页
            currentChangeHandleType(val) {
                this.pageNoType = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>
    #detailsPage .modelBox {
        height: calc(100vh - 266px);
        overflow: hidden;
    }

    .el-carousel__item >>> .el-image {
        height: 100%;
    }

    .el-carousel__item {
        text-align: center;
    }

    .el-popover >>> .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 150px;
        margin-left: 10px;
    }

    ::-webkit-scrollbar {
        /*display: none;*/
    }

    .info .el-button--small {
        font-size: 34px;
    }

    .word-wrap, .xlsx-wrap {
        overflow: auto;
    }

    .word-wrap >>> img, .xlsx-wrap >>> table {
        width: 100% !important;
    }

    .xlsx-wrap >>> tr {
        border-bottom: 1px solid #DCDCDC;
    }

    .xlsx-wrap >>> td {
        padding: 6px;
        text-align: center;
    }

    .table >>> .cell {
        text-align: center !important;
    }

    .audioBox {
        height: 100%;
        overflow: auto;
    }
</style>
